<template>
  <a-table
    class="table"
    :dataSource="dataSource"
    :columns="columns"
    rowKey="id"
    :row-selection="{...rowSelection}"
    :pagination="pagination"
    @change="handleTableChange">
    <template #action="{ record }">
          <a-button
            type="link"
            class="right-border" @click="goEdit(record.id)">编辑</a-button>
          <a-button
            type="link"
            class="right-border"
            style="color: green" @click="handelPreview(record)">预览</a-button>
          <a-button
            type="link"
            style="color: red" @click="deleteSourse(record.id)">删除</a-button>
        </template>
  </a-table>

   <!-- 图片预览 -->
    <div class="aaa" v-if="isShowImgDialog">
      <div class="" id="reviewImg">
        <a-image :width="0" :src="playUrl" alt="" />
      </div>
    </div>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref, watch, nextTick } from 'vue'
import { getMyEnc, deleteARSourse } from '@/api/subject/index.js'
import { showConfirm } from '@/common/hooks/index'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { OSSURL } from '@/common/hooks/oss-config'
const columns = [{
  title: '名称',
  dataIndex: 'name',
  key: 'name',
  // width: 80,
}, {
  title: '标签',
  dataIndex: 'tag_str',
  key: 'tag_str',
  width: 100,
}, {
  title: '描述',
  dataIndex: 'text_intro',
  key: 'text_intro',
  // width: 80,
}, {
  title: '',
  dataIndex: 'descrition',
  key: 'descrition',
  slots: { customRender: 'action' },
  align: 'right'
}]
export default defineComponent({
  props: ['subject_id', 'sourse'],
  setup(props) {
    const router = useRouter()
    const subject_id = computed(() => props.subject_id)
    const searchName = ref('')
    const selectedTags = ref([133]) // 已选标签
    const dataSource = ref([])
    const isShowImgDialog = ref(false)
    const playUrl = ref('')
    const pagination = reactive({
      total: 0,
      current: 1,
      pageSize: 10
    })
    const rowSelection = reactive({
      selectedRowKeys: [],
      selectedRows: [],
      onChange: (selectedRowKeys, selectedRows) => {
        rowSelection.selectedRowKeys = selectedRowKeys
        rowSelection.selectedRows = selectedRows
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    })
    const getMyArSourse = (searchValue, selectedTag, subjectId, page) => {
      if (page == 1) {
        pagination.current = 1
      }
      searchName.value = searchValue ? searchValue : searchName.value
      selectedTags.value = selectedTag ? selectedTag : selectedTags.value
      subject_id.value = subjectId ? subjectId : subject_id.value
      getMyEnc({
        search: searchName.value,
        subject_id: subject_id.value,
        tag_ids: selectedTags.value.toString(),
        page: pagination.current,
        pagesize: pagination.pageSize
      }).then((res) => {
        dataSource.value = res.data
        pagination.total = res.result
      })
    }
    const handleTableChange = (page) => {
      pagination.current = page.current
      getMyArSourse()
    }
    const goEdit = (id) => {
      router.push({name: 'createARsourse', params: {id: id}})
    }
    const deleteHttp = (_ids) => {
      deleteARSourse({ ids: _ids }).then(res => {
        message.success('删除成功')
        getMyArSourse()
      })
    }
    const deleteSourse = (id) => {
      showConfirm({
        content: '确定要删除该AR资源吗?',
        onOk: () => {
          deleteHttp([id])
        },
      })
    }

    // 批量删除
    const batchDelete = () => {
      showConfirm({
        content: '确定要删除AR资源吗?',
        onOk: () => {
          deleteHttp(rowSelection.selectedRowKeys)
        },
      })
    }

    // 预览
    const handelPreview = (obj) => {
      playUrl.value = OSSURL + obj.icon_path
      isShowImgDialog.value = true
      nextTick(() => {
        document.getElementById('reviewImg').getElementsByClassName('ant-image-img')[0].click()
      })
    }

    // 全选
    const allCheckData = (isAllChecked) => {
      console.log(isAllChecked)
      if (isAllChecked) {
        rowSelection.selectedRowKeys = dataSource.value.map(item => item.id)
      } else {
        rowSelection.selectedRowKeys = []
      }
    }
    // 搜索
    const handelSearch = (searchValue) => {
      pagination.current = 1
      searchName.value = searchValue
      getMyArSourse()
    }
    return {
      columns,
      dataSource,
      pagination,
      handleTableChange,
      rowSelection,
      goEdit,
      deleteSourse,
      isShowImgDialog,
      playUrl,
      handelPreview,
      batchDelete,
      allCheckData,
      handelSearch,
      getMyArSourse
    }
  },
})
</script>
<style lang="scss" scoped>
 .right-border {
    border-right: 1px solid #ccc !important;
  }
  ::v-deep .ant-table-tbody > tr > td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
