import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import { createVNode } from 'vue'

export const compareData = (oldList, newList) => {
  const _oldListId = oldList.map(item => item.id)
  const _newListId = newList.map(item => item.id)
  return !(_oldListId.toString() === _newListId.toString())
}

export const showConfirm = ({ title, content, onOk, onCancel }) => {
  Modal.confirm({
    title,
    icon: createVNode(ExclamationCircleOutlined),
    content,
    okText: '确定',
    cancelText: '取消',
    onOk() {
      onOk && onOk()
    },
    onCancel() {
      onCancel && onCancel()
    },
  })
}

export const throttle = {
  debounceIdentify: 0,
  bounce(func, delay) {
    this.debounceIdentify && clearTimeout(this.debounceIdentify)
    this.debounceIdentify = setTimeout(() => {
      func.apply(this, arguments)
    }, delay || 300)
  },
}
/**
 * clickoutside: 传递数组参数['点击目标点弹出drag的class名', '当前drag的id值(drag需要挂在到该id下)']
 */

export const clickoutside = {
  mounted(el, binding) {
    function handler(e) {
      const _node = document.getElementById(binding.arg[1])
      const _closeNode = _node
        ? _node.getElementsByClassName('ant-drawer-close')[0]
        : undefined
      if (_closeNode) {
        if (
          (el.contains(e.target) && !el.contains(_closeNode)) ||
          document.getElementsByClassName(binding.arg[0])[0].contains(e.target)
        ) {
          binding.value(true)
        } else {
          binding.value(false)
        }
      }
    }
    el._zClickOutside = handler
    document.addEventListener('click', handler)
  },
}

export const filterKeys = (obj1, obj2) => {
  Object.keys(obj1).forEach(key => {
    obj1[key] = obj2[key]
  })
}

export const checkEmoji = (params) => {
  let regex = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig;
  params = params.replace(regex, '')
  return params
}

export const isPassword = (value, msg = '密码不能为空') => {
  const reg = /^[_a-zA-Z0-9]{6,18}$/
  if (value == '' || value == undefined || value == null) {
   message.error(msg)
   return false
  } 
  return true
}
// 验证内容不能为空
export const isLenNull = (value, msg = '内容不能为空') => {
  if (value == '' || value == undefined || value == null) {
    message.error(msg)
    return false
  }
  return true
}
// 验证内容的长度是否符合
export const isLen = (value, msg = '填写的内容不符合', min = 1, max = 200) => {
  if (!(value.trim().length >= min && value.trim().length <= max)) {
    message.error(msg)
    return false
  }
  return true
}

export const getLastPage = (page, pagesize, total) => {
  let _nowTotalPage =  Math.ceil(total / pagesize)
  if (page > _nowTotalPage && _nowTotalPage > 0) return _nowTotalPage
}
